@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'garamond-medium';
  src: url('./assets/fonts/EBGaramond-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'garamond-italic';
  src: url('./assets/fonts/EBGaramond-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'garamond-regular';
  src: url('./assets/fonts/EBGaramond-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'garamond-semibold';
  src: url('./assets/fonts/EBGaramond-SemiBold.ttf') format('truetype');
}

.fade-in {
  opacity: 0;
  transform: translateY(-10px);

  transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1),transform 1s cubic-bezier(0.645, 0.045, 0.355, 1) ;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
  animation: upAndDown 1s ease-out;
}
@keyframes upAndDown {
  0% {
    transform: translateY(-15px);
    opacity: 0.7;
  }
  50% {
    transform: translateY(15px);
        opacity: .8;

  }
  100% {
    transform: translateY(0);
        opacity: 1;

  }
}
